import { useState } from "react"

import { EVideoFormat, EVideoTaskSource } from "../@types/snippet_types"
import { generateThumbnailFromVideo } from "../components/snippets/EditorTimeline"
import SupportedTranscriptionLanguages from "../public/data/transcription_languages.json"
import api from "../services/root_service"
import { getVideoFileMetadata } from "../utils/video_util"

const getYoutubeThumbnailImageSource = (url: string) => `https://img.youtube.com/vi/${url}/0.jpg`

interface IUploadedVideoSource {
    type: EVideoTaskSource.Upload
    src: File
}

interface IYoutubeVideoSource {
    type: EVideoTaskSource.Youtube
    src: string
}

type TVideoSource = IUploadedVideoSource | IYoutubeVideoSource

export interface IVideoSourceConfig {
    language: TLanguage
    format: EVideoFormat
}

export type TVideoSourceResult = TVideoSource & {
    title: string
    thumbnailUrl: string
    language?: TLanguage
    format?: EVideoFormat
    tracks: {
        original: string;
        deepgram: string | null;
    }[]
    requiresDetection: boolean
    duration: number
}

export type TLanguage =
    | (typeof SupportedTranscriptionLanguages)[number]
    | { dg_code: null; as_code: null; name: string }

export const defaultVideoSourceLanguage = {
    dg_code: null,
    as_code: null,
    name: "Auto detect" as string,
}

const getLanguageFields = (code?: string) => {
    if (!code) {
        return defaultVideoSourceLanguage
    }

    const supportedLanguage = SupportedTranscriptionLanguages.find((lang) => lang.dg_code === code)

    if (supportedLanguage) {
        return supportedLanguage
    }

    return defaultVideoSourceLanguage
}

const useVideoSource = (dimensions: { width: number; height: number }) => {
    const [source, setSource] = useState<TVideoSourceResult>()

    const getVideoSourceForUpload = async (file: File): Promise<TVideoSourceResult> => {
        const blobVideoUrl = URL.createObjectURL(file)
        const thumbnailImageUrl = await generateThumbnailFromVideo({
            videoUrl: blobVideoUrl,
            ...dimensions,
            time: 10,
        })
        const { duration } = await getVideoFileMetadata(file)
        return {
            type: EVideoTaskSource.Upload,
            src: file,
            title: file.name,
            thumbnailUrl: thumbnailImageUrl,
            language: getLanguageFields(),
            tracks: [],
            requiresDetection: true,
            duration,
        }
    }

    const getVideoSourceForYoutube = async (url: string): Promise<TVideoSourceResult> => {
        const { thumbnailUrl, success, title, tracks, requiresDetection, duration } =
            await api().postSnippetValidateYoutubeVideo({
                videoUrl: url,
            })

        const youtubeVideoSource: TVideoSourceResult = {
            type: EVideoTaskSource.Youtube,
            src: url,
            title: title || "Youtube Video",
            thumbnailUrl: "",
            requiresDetection,
            tracks,
            duration,
        }

        let selectedLanguage: string | undefined
        if (tracks.length === 1) {
            selectedLanguage = tracks[0].deepgram
        }
        const language = getLanguageFields(selectedLanguage)
        youtubeVideoSource.language = language

        if (!success || !thumbnailUrl) {
            const thumbnailImageSource = getYoutubeThumbnailImageSource(url)
            youtubeVideoSource.thumbnailUrl = thumbnailImageSource
        } else if (thumbnailUrl) {
            youtubeVideoSource.thumbnailUrl = thumbnailUrl
        }

        return youtubeVideoSource
    }

    const initialize = async ({ src, type }: TVideoSource) => {
        if (type === EVideoTaskSource.Upload) {
            const videoSourceForUpload = await getVideoSourceForUpload(src)
            setSource(videoSourceForUpload)
        } else {
            const videoSourceForYoutube = await getVideoSourceForYoutube(src)
            setSource(videoSourceForYoutube)
        }
    }

    const restore = async (videoSourceResult: TVideoSourceResult) => {
        setSource(videoSourceResult)
    }

    const updateConfig = (config: Partial<IVideoSourceConfig>) => {
        if (!source) {
            return
        }

        setSource({
            ...source,
            ...config,
        })
    }

    const reset = () => {
        setSource(undefined)
    }

    return {
        initialize,
        restore,
        updateConfig,
        source,
        reset,
    }
}

export default useVideoSource
