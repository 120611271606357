export enum EFileType {
    IMAGE = "image",
    VIDEO = "video",
    FILE = "application",
    AUDIO = "audio",
}

export enum EImageFormat {
    JPEG = "JPEG",
}

// sizes in bytes
export const FileLimitSizes: Record<EFileType, number> = {
    [EFileType.VIDEO]: 1000000000, //1 Gb
    [EFileType.AUDIO]: 100000000, //100 Mb
    [EFileType.IMAGE]: 100000000, //100 Mb
    [EFileType.FILE]: 10000000, //10 Mb
}

export enum EMimeTypes {
    mp3 = "audio/mpeg",
    mp4 = "video/mp4",
    csv = "text/csv",
    text = "text/plain",
}
