import { Flex, Progress, Text } from "@chakra-ui/react"

import { TProgressBarVariants } from "../configs/theme"

const ProgressLoadBar = ({
    percentageComplete,
    variant = "livelink",
}: {
    percentageComplete: number
    variant?: TProgressBarVariants
}) => {
    const hideText = variant === "project-options"

    return (
        <Flex
            width="100%"
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="relative"
        >
            <Progress
                variant={percentageComplete === 0 ? "loading" : variant}
                value={percentageComplete}
                isAnimated={true}
                isIndeterminate={percentageComplete === 0}
                alignSelf="flex-start"
                width="full"
                borderRadius="10px"
                height="10px"
                marginBottom="2px"
                css={{
                    "> div:first-of-type": {
                        transitionProperty: "width",
                        transitionDuration: "3s",
                        animationDuration: "3s",
                        borderRadius: "10px",
                    },
                }}
            />
            {!hideText && (
                <Text
                    as="span"
                    fontWeight={600}
                    fontSize="12px"
                    paddingTop="5px"
                    paddingBottom="5px"
                    pointerEvents="none"
                    userSelect="none"
                >
                    {`${percentageComplete.toFixed(0)}% Complete`}
                </Text>
            )}
        </Flex>
    )
}

export default ProgressLoadBar
