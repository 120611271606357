import crypto from "crypto"

export const encodeString = (str: string, key: string) => {
    const iv = crypto.randomBytes(16)
    const cipher = crypto.createCipheriv("aes-256-cbc", Buffer.from(key), iv)
    let encodedStr = cipher.update(str, "utf8", "hex")
    encodedStr += cipher.final("hex")
    return iv.toString("hex") + encodedStr
}

export const decodeString = <T extends string>(encodedStr: string, key: string) => {
    const iv = Buffer.from(encodedStr.slice(0, 32), "hex")
    const decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(key), iv)
    let decodedStr = decipher.update(encodedStr.slice(32), "hex", "utf8")
    decodedStr += decipher.final("utf8")
    return decodedStr as T
}

export const getSha256HMac = (secret: string) => {
    return crypto.createHmac("sha256", secret)
}

export const createHashHexForString = (str: string) => {
    return crypto.createHash("sha256").update(str).digest("hex")
}
