import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertStatus,
    AlertTitle,
    Button,
    Flex,
    Text,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { FC, useContext, useMemo, useState } from "react"

import {
    EPlatformSubscriptionPlanFrequency,
    EPlatformSubscriptionType,
    ESubscriptionStatus,
} from "../../@types/subscription_types"
import { BaseUser } from "../../server/dao/user_dao"
import api from "../../services/root_service"
import { PlatformSubscriptionModalContext } from "../../utils/context_util"

interface ISnippetSubscriptionAlertContainerProps {
    currentUser?: BaseUser
}

interface IAlert {
    title: string
    message: string
    status: AlertStatus
    info?: string
    cta?: {
        text: string
        handler: () => void
    }
}

const SnippetSubscriptionAlertContainer: FC<ISnippetSubscriptionAlertContainerProps> = ({
    currentUser,
}) => {
    const router = useRouter()
    const [isCtaLoading, setIsCtaLoading] = useState(false)

    const platformSubscriptionModalRef = useContext(PlatformSubscriptionModalContext)

    const handleOpenStripeCustomerPortal = async () => {
        setIsCtaLoading(true)
        const { url } = await api().postCreatePlatformSubscriptionPortalSession({
            returnUrl: router.asPath,
        })
        window.location.href = url
    }

    const handleOpenPlatformSubscriptionModal = async () =>
        platformSubscriptionModalRef?.current?.open()

    const handleContactUsBtn = () => window.open("mailto:support@livelink.vip", "_blank")
    const subscription = useMemo(() => {
        if (!currentUser || !currentUser?.platformSubscriptions.length) {
            return
        }
        return currentUser.platformSubscriptions[0]
    }, [currentUser])

    const alertProps = useMemo<IAlert | void>(() => {
        if (!subscription) {
            return
        } else {
            const isTopTierPlan =
                subscription.plan.name === EPlatformSubscriptionType.SNIPPETS_PRO &&
                subscription.interval === EPlatformSubscriptionPlanFrequency.YEAR

            const { status } = subscription
            if (status === ESubscriptionStatus.PAST_DUE) {
                return {
                    status: "error",
                    title: "Payment failed!",
                    message: "One or more of your subscription payments are past due.",
                    cta: {
                        text: "Fix now",
                        handler: handleOpenStripeCustomerPortal,
                    },
                }
            }

            const { cardExpiresAt } = subscription
            const daysUntilExpiry = dayjs(cardExpiresAt).diff(dayjs(), "days")
            if (daysUntilExpiry <= 30) {
                return {
                    status: "warning",
                    title: "Check your payment method!",
                    message:
                        daysUntilExpiry <= 0
                            ? "Your payment method has expired."
                            : `Looks like your payment method is expiring ${
                                  daysUntilExpiry < 8
                                      ? `in ${daysUntilExpiry} ${
                                            daysUntilExpiry < 2 ? "day" : "days"
                                        }`
                                      : "soon"
                              }.`,
                    cta: {
                        text: "Update now",
                        handler: handleOpenStripeCustomerPortal,
                    },
                }
            }

            const { usedMinutes, usedStorage } = currentUser!
            const { totalMinutes, totalStorage } = subscription.plan
            if (usedMinutes && usedStorage && totalMinutes && totalStorage) {
                const exceededMinutes = usedMinutes >= totalMinutes
                const exceededStorage = BigInt(usedStorage) >= BigInt(totalStorage)

                const preWarnMinutes = usedMinutes >= totalMinutes * 0.9
                const preWarnStorage = usedStorage >= Number(totalStorage) * 0.9

                if (exceededMinutes || exceededStorage) {
                    const resource = (() => {
                        if (exceededMinutes && exceededStorage) {
                            return "minutes and storage"
                        } else if (exceededMinutes) {
                            return "minutes"
                        } else {
                            return "storage"
                        }
                    })()
                    const actionableText = isTopTierPlan
                        ? "get in touch for more allowance."
                        : "upgrade your plan."
                    const renewalDate = dayjs(subscription.expiresAt).format(
                        "DD/MM/YYYY [at] HH:mm",
                    )
                    return {
                        status: "error",
                        title: `You've ran out of ${resource}!`,
                        message: `You won't be able to create new clips until your subscription has renewed. Alternatively, ${actionableText}`,
                        info: `Subscription renews on: ${renewalDate}`,
                        cta: {
                            text: isTopTierPlan ? "Contact us" : "Upgrade",
                            handler: isTopTierPlan
                                ? handleContactUsBtn
                                : handleOpenPlatformSubscriptionModal,
                        },
                    }
                } else if (preWarnMinutes || preWarnStorage) {
                    return {
                        status: "warning",
                        title: `You're about to run out of ${preWarnMinutes ? "minutes" : ""}${
                            preWarnMinutes && preWarnStorage ? " and " : ""
                        }${preWarnStorage ? "storage" : ""}! 😱`,
                        message: `New clips will likely take you over your total allowance. Please wait until your subscription renews, or ${
                            isTopTierPlan
                                ? "get in touch for more allowance."
                                : "upgrade your plan."
                        }`,
                        info: `Subscription renews on: ${dayjs(subscription.expiresAt).format(
                            "DD/MM/YYYY [at] HH:mm",
                        )}`,
                        cta: {
                            text: isTopTierPlan ? "Contact us" : "Upgrade",
                            handler: isTopTierPlan
                                ? handleContactUsBtn
                                : handleOpenPlatformSubscriptionModal,
                        },
                    }
                }
            }
        }
        return
    }, [currentUser?.platformSubscriptions])

    if (!currentUser) {
        return null
    }

    if (!alertProps) {
        return null
    }

    return (
        <Alert
            status={alertProps.status}
            display="flex"
            flexDir="row"
            justifyContent="space-between"
            marginTop="20px"
        >
            <Flex direction="row">
                <AlertIcon />
                <Flex direction="column" gap={2}>
                    <AlertTitle>{alertProps.title}</AlertTitle>
                    <AlertDescription>{alertProps.message}</AlertDescription>
                    {alertProps.info && (
                        <AlertDescription fontSize="sm" fontStyle="italic">
                            {alertProps.info}
                        </AlertDescription>
                    )}
                </Flex>
            </Flex>
            {alertProps.cta && (
                <Button
                    onClick={alertProps.cta.handler}
                    isLoading={isCtaLoading}
                    variant="primary"
                    marginLeft={16}
                    paddingX={6}
                >
                    <Text as="span" color="white">
                        {alertProps.cta.text}
                    </Text>
                </Button>
            )}
        </Alert>
    )
}

export default SnippetSubscriptionAlertContainer
