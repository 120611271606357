import { FirebaseError } from "firebase-admin"
import {
    getDownloadURL,
    getMetadata,
    getStorage,
    ref,
    StorageError,
    uploadBytes,
    uploadBytesResumable,
} from "firebase/storage"
import Resizer from "react-image-file-resizer"

import { EFileType, EMimeTypes, FileLimitSizes } from "../@types/file_types"
import { isMobileBrowser } from "./browser_util"

export const uploadFileToFirebase = async (
    file: File,
    fileName: string,
    uploadPath: string,
    onProgressChange?: (progress: number) => void,
    onComplete?: (url: string) => void,
    onError?: (error: FirebaseError | StorageError) => void,
) => {
    if (!file) {
        return
    }

    const path = fileName.split(".")
    const extension = path[path.length - 1]

    const storage = getStorage()
    const storageRef = ref(storage, `${uploadPath}/${fileName}.${extension}`)

    const metadata = {
        contentType: file.type,
        customMetadata: {
            fileName: file.name,
        },
    }

    try {
        if (!!onProgressChange) {
            const uploadTask = uploadBytesResumable(storageRef, file, metadata)

            uploadTask.on(
                "state_changed",
                async (snapshot) => {
                    const progress = Math.floor(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                    )
                    onProgressChange(progress)
                },
                (e: StorageError) => {
                    onError?.(e)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        onComplete!(url as string)
                    })
                },
            )
        } else {
            const uploadTask = await uploadBytes(storageRef, file, metadata)
            const fileUrl = await getDownloadURL(uploadTask.ref)
            return fileUrl
        }
    } catch (error) {
        onError?.(error)
    }
}

export const resizeImage = (
    file: Blob,
    width: number,
    height: number,
    compressFormat: string,
    quality = 80,
): Promise<File> =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            compressFormat,
            quality,
            0,
            (uri) => {
                resolve(uri as File)
            },
            "file",
        )
    })

export const retrieveFileName = async (
    fileRef: string,
    onError?: (error: FirebaseError) => string,
) => {
    try {
        const storage = getStorage()
        const storageRef = ref(storage, fileRef)

        return await getMetadata(storageRef)
            .then((metadata) => {
                // @ts-ignore
                return metadata.customMetadata.fileName
            })
            .catch((error) => {
                onError?.(error)
            })
    } catch (error) {
        console.error(error)
    }
}

export const retrieveFileMeta = (file: File) => {
    // e.g. audio/wav
    const fileType = file.type.slice(0, file.type.indexOf("/")) as EFileType //audio
    const fileFormat: string = file.type.split("/")[1] //wav
    const fileName: string = file.name
    const fileSize: number = file.size

    return { fileType, fileFormat, fileName, fileSize }
}

export const convertBytesToSizeStr = (bytes: number | bigint, decimals = 2): string => {
    let bytesToConvert = bytes as number
    if (typeof bytes === "bigint") {
        bytesToConvert = Number(BigInt(bytes)) as number
    }

    if (bytes === 0) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

    const i = Math.floor(Math.log(bytesToConvert) / Math.log(k))

    return parseFloat((bytesToConvert / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

// size units in bytes
export const fileExceedsLimit = (file: File, fileLimitInBytes: number): boolean =>
    file.size > fileLimitInBytes

export const validateFileSize = (file: File, fileType: EFileType) => {
    const limit = FileLimitSizes[fileType]
    if (fileExceedsLimit(file, limit)) {
        throw new Error(`File exceeds the maximum limit (${convertBytesToSizeStr(limit)})`)
    }
}

export const downloadFile = (data: BlobPart[], fileName: string, type?: EMimeTypes) => {
    const blob = new Blob(data, { type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")

    document.body.appendChild(link)

    link.setAttribute("href", url)
    link.setAttribute("download", fileName)
    link.setAttribute("target", "_blank")
    link.click()

    setTimeout(() => {
        document.body.removeChild(link)
    }, 100)
}

export const downloadFileAndOpenDialog = async (
    url: string,
    fileName: string,
    type: EMimeTypes,
) => {
    const response = await fetch(url)
    const data = await response.blob()
    const file = new File([data], fileName, { type })

    const useNativeShare =
        isMobileBrowser() && "share" in navigator && navigator.canShare({ files: [file] })
    if (useNativeShare) {
        const shareData: ShareData = {
            title: `Download ${fileName}`,
            files: [file],
        }

        try {
            navigator.share(shareData)
        } catch (error) {}
    } else {
        downloadFile([data], fileName, type)
    }
}
