let inMemoryTempStorage: Map<string, unknown> | null = null

export const useTempStorage = () => {
    const initiate = () => {
        if (inMemoryTempStorage === null) {
            inMemoryTempStorage = new Map()
        }
    }

    const setItem = <T>(key: string, value: T) => {
        if (!inMemoryTempStorage) {
            return
        }
        inMemoryTempStorage.set(key, value)
    }

    const getItem = <T>(key: string): T | null => {
        const value = inMemoryTempStorage?.get(key)
        return (value as T) || null
    }

    const clearStorage = () => {
        if (inMemoryTempStorage) {
            inMemoryTempStorage.clear()
        }
    }

    return { initiate, setItem, getItem, clearStorage }
}
