import { Button, Flex, Heading, Icon, Input, InputGroup, keyframes, Text } from "@chakra-ui/react"
import { InferGetServerSidePropsType } from "next"
import { AuthAction, withAuthUser } from "next-firebase-auth"
import { useRouter } from "next/router"
import { useEffect, useMemo, useRef, useState } from "react"
import { FaYoutube } from "react-icons/fa"
import { FiUpload } from "react-icons/fi"

import { AE, AnalyticsEnhancedConversionEvents, LinkedInEvents } from "../@types/analytics"
import { EAnimation } from "../@types/global_types"
import { EVideoTaskSource } from "../@types/snippet_types"
import {
    EPlatformSubscriptionStatus,
    EPlatformSubscriptionUpdateType,
} from "../@types/subscription_types"
import MetaTags from "../components/MetaTags"
import CreateClipsButton from "../components/snippets/CreateClipsButton"
import ProjectOptionsSelector from "../components/snippets/ProjectOptionsSelector"
import SnippetSubscriptionAlertContainer from "../components/snippets/SnippetSubscriptionAlertContainer"
import _c from "../configs/constants"
import AlertController from "../controllers/alert_controller"
import Analytics from "../controllers/analytics_controller"
import { useTempStorage } from "../hooks/useTempStorage"
import useVideoSource, { TVideoSourceResult } from "../hooks/useVideoSource"
import { PlatformSubscriptionController } from "../server/controllers/platform_subscription_controller"
import { removeParamsFromUrl } from "../utils/browser_util"
import { EFaceDetectionSpeed, getCampaignProperties } from "../utils/campaign_util"
import { printMoney } from "../utils/currency_util"
import { EError } from "../utils/error_util"
import { convertBytesToSizeStr, fileExceedsLimit } from "../utils/file_util"
import { linkToSnippetProjects } from "../utils/link_util"
import { _l } from "../utils/logging_util"
import { IGetServerSidePropsContext, withAppContext } from "../utils/ssr_util"
import { isUserSubscribedToPlatform } from "../utils/user_util"
import { getVideoFileMetadata } from "../utils/video_util"

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

const VIDEO_FILE_EXTENSIONS: string[] = [
    ".mp4",
    ".avi",
    ".mov",
    ".wmv",
    ".flv",
    ".mkv",
    ".webm",
    ".m4v",
    ".3gp",
    ".mpeg",
    ".mpg",
]

export interface ISnippetsPageProps
    extends InferGetServerSidePropsType<typeof fetchDataRequirements> {}

const SnippetsPage = ({ currentUser, totalDiscount, hasSlowFdCookie }: ISnippetsPageProps) => {
    const router = useRouter()

    const videoSource = useVideoSource({
        width: 370,
        height: 200,
    })

    const [videoUrl, setVideoUrl] = useState<string | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<"input" | "format">("input")
    const [error, setError] = useState<string | null>(null)
    const [file, setFile] = useState<File | null>(null)

    const initializeAfterMountRef = useRef(false)

    const processingTaskIdRef = useRef<string>()
    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const tempStorage = useTempStorage()

    useEffect(() => {
        tempStorage.initiate()
    }, [])

    const promocode = useMemo(() => {
        if (totalDiscount && currentUser) {
            const amountOff = printMoney({
                total: totalDiscount,
                options: { currency: currentUser.platformSubscriptions[0].plan.currency },
            })

            return `${currentUser.platformSubscriptions[0].stripePromoCode} (${amountOff} OFF)`
        }
    }, [totalDiscount, currentUser])

    useEffect(() => {
        if (router.isReady) {
            if (router.query.projectId) {
                const projectId = router.query.projectId as string
                const tempVideoSource = tempStorage.getItem<TVideoSourceResult>(projectId)
                if (!tempVideoSource) {
                    return
                }
                processingTaskIdRef.current = projectId
                videoSource.restore(tempVideoSource)
                setPage("format")
                removeParamsFromUrl(router)
                return
            }
            if (router.query.deselect) {
                removeParamsFromUrl(router)
            }
            if (
                !!router.query.url &&
                (router.query.page === "format" || router.query.retry === "true")
            ) {
                initializeAfterMountRef.current = true
                videoSource.initialize({
                    type: EVideoTaskSource.Youtube,
                    src: router.query.url as string,
                })
                .catch((e) => {
                    const canShowError = e.name === EError.APIResponseError
                    if (canShowError) {
                        setError(e.message)
                    } else {
                        setError("Failed to get video information. Please try another video.")
                    }
                })
            }
            if (router.query.action) {
                if (currentUser.platformSubscriptions.length > 0) {
                    const subscription = currentUser.platformSubscriptions[0]
                    Analytics.trackEvent(AE.AISnippet_SubscriptionFinish, {
                        send_to: AnalyticsEnhancedConversionEvents[AE.AISnippet_SubscriptionFinish],
                        subscriptionId: subscription.id,
                        email: currentUser!.email,
                        plan: router.query.plan as string,
                        value: parseInt(router.query.value as string),
                        currency: router.query.currency as string,
                        status:
                            router.query.action === "upgrade"
                                ? EPlatformSubscriptionStatus.UPGRADED
                                : EPlatformSubscriptionStatus.SCHEDULED_DOWNGRADE,
                        promocode,
                        user_data: { email: currentUser.email },
                    })
                }

                AlertController.show({
                    title: "Your subscription has been updated!",
                    message: "Please check your email for more details about your new plan.",
                    primaryAction: {
                        label: "OK",
                        onClick: () => removeParamsFromUrl(router),
                    },
                    animation: EAnimation.confetti,
                    isDismissable: true,
                    onDismiss: () => removeParamsFromUrl(router),
                })
            } else if (
                router.query.subscribed &&
                router.query.plan &&
                router.query.value &&
                router.query.currency
            ) {
                if (currentUser.platformSubscriptions.length > 0) {
                    const subscription = currentUser.platformSubscriptions[0]
                    Analytics.trackEvent(AE.AISnippet_SubscriptionFinish, {
                        send_to: AnalyticsEnhancedConversionEvents[AE.AISnippet_SubscriptionFinish],
                        subscriptionId: subscription.id,
                        email: currentUser!.email,
                        plan: router.query.plan as string,
                        value: parseInt(router.query.value as string),
                        currency: router.query.currency as string,
                        status: EPlatformSubscriptionStatus.TRIAL,
                        promocode,
                        user_data: { email: currentUser.email },
                    })
                }

                Analytics.trackLinkedInConversion(LinkedInEvents.LINKEDIN_AI_SUBSCRIPTION_FINISH)

                AlertController.show({
                    title: "You're subscribed!",
                    message: "Enjoy full access to all the features included in your plan.",
                    primaryAction: {
                        label: "OK",
                        onClick: () => removeParamsFromUrl(router),
                    },
                    animation: EAnimation.confetti,
                    isDismissable: true,
                    onDismiss: () => removeParamsFromUrl(router),
                })
            }
            if (!!router.query.emailReturn) {
                Analytics.trackEvent(AE.AISnippet_ResultsView, {
                    type: "email",
                    result: "error",
                    isDummyMode: false,
                })
            }
        }
    }, [router.query, router.isReady, currentUser, videoUrl])

    const validateUploadedFileDuration = async (file: File) => {
        const { duration } = await getVideoFileMetadata(file)
        if (duration <= _c.SNIPPET_LOWER_DURATION_LIMIT) {
            throw new Error("Please upload a video longer than 5 minutes to get the best results.")
        } else if (duration > _c.SNIPPET_UPPER_DURATION_LIMIT) {
            throw new Error("Please upload a video no longer than 2 hours to get the best results.")
        }
    }

    const isValidExtension = (file: File, validExtensions: string[]) => {
        const fileName = file.name
        const fileExtension = fileName.slice(fileName.lastIndexOf("."))
        return validExtensions.includes(fileExtension.toLowerCase())
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null)
        const file = event.target.files?.item(0)
        if (!file) {
            return
        }

        try {
            setLoading(true)
            if (fileExceedsLimit(file, _c.SNIPPET_VIDEO_SIZE_LIMIT)) {
                throw new Error(
                    `File exceeds the maximum limit (${convertBytesToSizeStr(
                        _c.SNIPPET_VIDEO_SIZE_LIMIT,
                    )})`,
                )
            }

            if (!isValidExtension(file, VIDEO_FILE_EXTENSIONS)) {
                throw new Error(
                    `Invalid file type. Allowed types are: ${VIDEO_FILE_EXTENSIONS.join(", ")}`,
                )
            }
            await validateUploadedFileDuration(file)
            setFile(file)
            setVideoUrl(file.name)
        } catch (error) {
            setError(error.message)
        }
        setLoading(false)
    }

    const handleSelectFile = () => {
        Analytics.trackEvent(AE.AISnippet_UploadFileClick)
        setError(null)
        fileInputRef?.current?.click()
    }

    const handleYoutubeUrlOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        Analytics.trackEvent(AE.AISnippet_PasteYoutubeUrl, { url: e.target.value })
        setFile(null)
        setError(null)
        setVideoUrl(e.target.value)
    }

    const handleClickNextQuestion = async () => {
        if (page === "input") {
            if (!file && !videoUrl) {
                setError("Please provide a youtube url or upload a video.")
                return
            }

            setLoading(true)

            try {
                if (!file && videoUrl) {
                    await videoSource.initialize({
                        type: EVideoTaskSource.Youtube,
                        src: videoUrl,
                    })
                } else if (file) {
                    await videoSource.initialize({
                        type: EVideoTaskSource.Upload,
                        src: file,
                    })
                }
            } catch (e) {
                setError(e.message)
                setLoading(false)
                return
            }

            Analytics.trackEvent(AE.AISnippet_CreateClips, {
                location: "landing-page",
                videoUrl: videoUrl ?? "",
                isDummyMode: !videoUrl && !file,
            })

            Analytics.trackLinkedInConversion(LinkedInEvents.LINKEDIN_AI_CREATE_CLIPS)

            setPage("format")
            setLoading(false)
            return
        }
    }

    const renderGenerateSnippetsSection = () => (
        <Flex direction="column" width={["100%", "535px"]}>
            <InputGroup
                style={{
                    boxShadow:
                        "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11)",
                }}
                role="group"
                display="flex"
                size="lg"
                borderRadius="md"
                padding="3px"
                transition="background 0.3s ease"
                background={
                    isLoading
                        ? "linear-gradient(45deg, #eee, #ddd, #ccc)"
                        : "linear-gradient(45deg, #F6E543, #6AFB53, #53FBD2, #5396FB, #913BFE, #FE3BBC)"
                }
                backgroundSize="150% 150%"
                _hover={{
                    animation: isLoading ? "none" : `${gradientAnimation} 3s ease infinite`,
                }}
            >
                <Flex
                    flex="1"
                    background="white"
                    borderRadius="md"
                    padding="6px"
                    height="45px"
                    justifyContent="center"
                    alignItems="center"
                    _hover={{
                        backgroundColor: "#f7f7f7",
                    }}
                >
                    <Icon
                        width={videoUrl ? "15%" : ""}
                        transition="width 0.5s ease"
                        as={FaYoutube}
                        color="gray.600"
                        fontSize={25}
                    />
                    <Input
                        type="url"
                        borderWidth={0}
                        onFocus={() => setError(null)}
                        focusBorderColor="none"
                        fontSize="16px"
                        value={videoUrl ?? ""}
                        isDisabled={isLoading}
                        color="black"
                        placeholder="Paste a YouTube video link"
                        _placeholder={{ opacity: 1, color: "gray.600" }}
                        onChange={handleYoutubeUrlOnChange}
                        width={videoUrl ? "85%" : "230px"}
                        transition="width 0.5s ease"
                    />
                </Flex>
            </InputGroup>
            {error && (
                <Text fontSize={12} color="red" mt="5px">
                    {error}
                </Text>
            )}

            <Text p={4} color="gray.500" fontSize="14px" textAlign="center">
                or
            </Text>

            <Button
                height="45px"
                variant="unstyled"
                display="flex"
                border="1px dashed gray"
                p={4}
                width="100%"
                fontSize="16px"
                fontWeight="500"
                borderRadius="md"
                mx="auto"
                color="gray.600"
                isDisabled={isLoading}
                _disabled={{
                    backgroundColor: "#f7f7f7",
                    _hover: {
                        backgroundColor: "#f7f7f7",
                        cursor: "default",
                    },
                }}
                onClick={handleSelectFile}
            >
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept={VIDEO_FILE_EXTENSIONS.join(",")}
                    onChange={handleFileChange}
                    disabled={isLoading}
                />
                <Icon as={FiUpload} mr={2} />
                <Text as="span" color="gray.600">
                    Upload video file
                </Text>
            </Button>

            <CreateClipsButton
                mt={5}
                width={["100%", "535px"]}
                currentUser={currentUser}
                location="landing-page"
                label={!currentUser?.snippetTasks?.length ? "Try for free" : "Create clips"}
                onPermittedClick={handleClickNextQuestion}
                isLoading={isLoading}
                isDisabled={isLoading || !!error}
            />
        </Flex>
    )

    const renderInputPage = () => {
        if (page !== "input") {
            return null
        }
        return (
            <Flex flexDir="column" alignItems="center" justifyContent="center">
                <Flex
                    flexDir="column"
                    height={{ base: "100px", md: "200px" }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <SnippetSubscriptionAlertContainer currentUser={currentUser} />
                </Flex>
                <Heading as="h2" textAlign="center" fontSize="38px" py={2} whiteSpace="pre-line">
                    {`Viral clips.\nMinimal effort.`}
                </Heading>
                <Text fontWeight="semibold" p={8} fontSize="22px" textAlign="center">
                    Use AI to turn your videos into short, shareable clips.
                </Text>
                {renderGenerateSnippetsSection()}
            </Flex>
        )
    }

    const renderFormatPage = () => {
        if (page !== "format" || !videoSource) {
            return null
        }

        const showFastOption =
            (hasSlowFdCookie || currentUser?.slowFdTest === EFaceDetectionSpeed.SlowChoice) &&
            !isUserSubscribedToPlatform(currentUser)

        const handleProcessComplete = () => {
            Analytics.trackEvent(AE.AISnippet_GenerateSnippets, {
                videoUrl: videoUrl ?? "",
                isDummyMode: !videoUrl && !file,
            })
            router.push(linkToSnippetProjects())
        }

        const handleClickTryAnother = () => {
            setPage("input")
            setVideoUrl(null)
            setFile(null)
            videoSource.reset()
        }

        return (
            <ProjectOptionsSelector
                video={videoSource.source}
                enableFastOption={showFastOption}
                onConfigurationChange={videoSource.updateConfig}
                existingTaskId={processingTaskIdRef.current}
                initializeAfterMount={initializeAfterMountRef.current}
                isDummyMode={!videoUrl && !file}
                isUserLoggedIn={!!currentUser}
                userEmail={currentUser?.email}
                onProcessComplete={handleProcessComplete}
                onRetry={handleClickTryAnother}
            />
        )
    }

    return (
        <Flex position="relative" flexDir="column" width="100vw" p={6}>
            <MetaTags title="Create clips" />
            {renderInputPage()}
            {renderFormatPage()}
        </Flex>
    )
}

const fetchDataRequirements = async ({
    services,
    currentUser,
    query,
    req,
}: IGetServerSidePropsContext) => {
    const { subscribed, planId, value, action, projectId, url, page } = query
    if (!_c.BASE_URL.includes("prod.preview") && _c.isProduction && !currentUser && !(url || projectId || page)) {
        return {
            redirect: {
                destination: "https://livelink.ai",
                permanent: false,
            },
        }
    }

    let totalDiscount
    if (currentUser && subscribed && planId && value) {
        await services.api.postCompletePlatformSubscriptionPurchase({
            planId: planId as string,
            action: action as EPlatformSubscriptionUpdateType,
        })

        totalDiscount = await PlatformSubscriptionController.retrieveTotalDiscountApplied(
            currentUser,
        )
    }

    const { slowFdTest } = getCampaignProperties(req.cookies)

    return {
        props: {
            currentUser: currentUser!,
            totalDiscount,
            projectId,
            hasSlowFdCookie: slowFdTest === EFaceDetectionSpeed.SlowChoice,
            appShell: {
                navbarOptions: {
                    transparent: "scroll",
                    hideShareButton: true,
                },
                disableFooter: false,
            },
        },
    }
}

export const getServerSideProps = withAppContext(fetchDataRequirements)

export default withAuthUser({
    whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
    whenUnauthedAfterInit: AuthAction.RENDER,
})(SnippetsPage)
