import { ChevronDownIcon } from "@chakra-ui/icons"
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    HStack,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Text,
    VStack,
} from "@chakra-ui/react"
import axios from "axios"
import { useRouter } from "next/router"
import { stringifyUrl } from "query-string"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { MdArrowForward } from "react-icons/md"

import { AE } from "../../@types/analytics"
import { EVideoFormat, EVideoTaskSource } from "../../@types/snippet_types"
import _c from "../../configs/constants"
import routes from "../../configs/routes"
import AlertController from "../../controllers/alert_controller"
import Analytics from "../../controllers/analytics_controller"
import useLocalStorage from "../../hooks/useLocalStorage"
import { useTempStorage } from "../../hooks/useTempStorage"
import {
    defaultVideoSourceLanguage,
    IVideoSourceConfig,
    TLanguage,
    TVideoSourceResult,
} from "../../hooks/useVideoSource"
import { TPostSnippetInitialiseBody } from "../../pages/api/v1/snippet/initialise"
import SupportedTranscriptionLanguages from "../../public/data/transcription_languages.json"
import { BaseSnippetTaskResponse } from "../../server/controllers/video_task_controller"
import api from "../../services/root_service"
import { PlatformSubscriptionModalContext } from "../../utils/context_util"
import { linkToSignup } from "../../utils/link_util"
import { containsSubstrings, detectUnsupportedScripts } from "../../utils/string_util"
import ProgressLoadBar from "../ProgressLoadBar"

const VideoFormatMapping = [
    {
        label: "Portrait (9:16)",
        value: EVideoFormat.Portrait,
        imageSrc: "/images/snippets/format_portrait.svg",
    },
    {
        label: "Landscape (16:9)",
        value: EVideoFormat.Landscape,
        imageSrc: "/images/snippets/format_landscape.svg",
    },
    {
        label: "Square (1:1)",
        value: EVideoFormat.Square,
        imageSrc: "/images/snippets/format_square.svg",
    },
]

type TVideoFormatOption = (typeof VideoFormatMapping)[number]

enum EOnboardingStage {
    Configure = "configure",
    InitUploading = "init-uploading",
    AwaitingSignUp = "awaiting-sign-up",
    Uploading = "uploading",
    Processing = "processing",
    Complete = "complete",
    Error = "error",
}

const [defaultVideoFormat] = VideoFormatMapping
const defaultErrorMessage = "Sorry, we cant process this particular video."

enum EErrorStackType {
    General = "General",
    Subscription = "Subscription",
    LanguageSupport = "LanguageSupport",
    MusicVideo = "MusicVideo",
}

type TErrorStack = {
    message: string
    type: EErrorStackType
}

interface IProjectOptionsSelectorProps {
    video?: TVideoSourceResult
    userEmail?: string
    enableFastOption: boolean
    isUserLoggedIn: boolean
    existingTaskId?: string
    initializeAfterMount: boolean
    isDummyMode: boolean
    onConfigurationChange: (config: Partial<IVideoSourceConfig>) => void
    onProcessComplete: () => void
    onRetry: () => void
}

const ProjectOptionsSelector = ({
    video,
    userEmail,
    isUserLoggedIn,
    existingTaskId,
    isDummyMode,
    onProcessComplete,
    onConfigurationChange,
    initializeAfterMount,
    onRetry,
    enableFastOption,
}: IProjectOptionsSelectorProps) => {
    const router = useRouter()
    const platformSubscriptionModalRef = useContext(PlatformSubscriptionModalContext)
    const progressIntervalRef = useRef<ReturnType<typeof setInterval>>()
    const isUserAuthedOnOnlyClientRef = useRef(false)
    const isInitializedRef = useRef(false)
    const ongoingTaskId = useRef<string | null>(null)
    const [firebaseUserId, setFirebaseUserId] = useState<string | null>(null)
    const [error, setError] = useState<TErrorStack | null>(null)
    const [currentStage, setCurrentStage] = useState(
        video?.type === EVideoTaskSource.Youtube
            ? EOnboardingStage.Configure
            : EOnboardingStage.InitUploading,
    )
    const [progress, setProgress] = useState(0)
    const [fauid, setFauid] = useLocalStorage({
        key: _c.LOCAL_STORAGE_KEYS.FIREBASE_USER_AUTH_ID,
        fallback: "",
    })
    const tempStorage = useTempStorage()
    const currentVideoFormat = useMemo(() => {
        if (!!video?.format) {
            return VideoFormatMapping.find((format) => format.value === video.format)!
        }
        return defaultVideoFormat
    }, [video?.format])

    const currentLanguage = video?.language || defaultVideoSourceLanguage
    const hasVideoError = error !== null
    const isYoutubeVideo = video?.type === EVideoTaskSource.Youtube
    const requiresLanguageSelection =
        isYoutubeVideo && currentLanguage.dg_code === null && !video?.requiresDetection
    const requiresUpload = [EOnboardingStage.InitUploading, EOnboardingStage.Uploading].includes(
        currentStage,
    )
    const isLoading = requiresUpload || currentStage === EOnboardingStage.Processing
    const isFormDisabled = isLoading || hasVideoError

    useEffect(() => {
        if (!firebaseUserId && !isUserAuthedOnOnlyClientRef.current) {
            if (fauid) {
                isUserAuthedOnOnlyClientRef.current = true
                setFirebaseUserId(fauid)
            }
        }
    }, [firebaseUserId, fauid])

    const getVideoSourceFormData = () => {
        if (!video) {
            return
        }

        const formData = new FormData()
        formData.append("file", video.src)
        formData.append("format", currentVideoFormat.value)
        formData.append("email", userEmail || "")

        if (currentLanguage.dg_code) {
            formData.append("languageCode", currentLanguage.dg_code)
        }

        if (firebaseUserId) {
            formData.append("firebaseUserId", firebaseUserId)
        }

        const taskId = existingTaskId || ongoingTaskId.current
        if (taskId) {
            formData.append("taskId", taskId)
        }

        return formData
    }

    useEffect(() => {
        if (hasVideoError && progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current)
            return
        }
        const updateProgressRandomly = () => {
            setProgress((prev) => {
                const newProgress = prev + Math.random() * 5
                return newProgress > 100 ? 98 : newProgress
            })
        }

        if (progress === 50) {
            progressIntervalRef.current = setInterval(updateProgressRandomly, 1_000)
        }
    }, [progress, hasVideoError])

    const uploadAndProcessVideo = async () => {
        try {
            if (!video || video.type !== EVideoTaskSource.Upload) {
                return
            }
            const formData = getVideoSourceFormData()
            if (!formData) {
                return
            }

            let { url } = routes.POST_PROCESS_SNIPPETS_UPLOAD

            if (firebaseUserId) {
                url = stringifyUrl({
                    url: routes.POST_PROCESS_SNIPPETS_UPLOAD.url,
                    query: { firebaseUserId },
                })
            }

            try {
                setCurrentStage(EOnboardingStage.Uploading)
                const response = await axios.post<BaseSnippetTaskResponse>(url, formData, {
                    headers: routes.POST_PROCESS_SNIPPETS_UPLOAD.options.headers,
                    onUploadProgress: ({ loaded, total }) => {
                        const totalFileSize = total || video.src.size
                        const fullProgress = (loaded / totalFileSize) * 100
                        setProgress(fullProgress / 2)
                    },
                })
                const task = response.data

                ongoingTaskId.current = task.id
                if (isUserAuthedOnOnlyClientRef.current) {
                    tempStorage.clearStorage()
                }
                Analytics.trackEvent(AE.AISnippet_UploadVideo, {
                    format: currentVideoFormat.value,
                    language: currentLanguage.dg_code ?? null,
                    title: video.title,
                })
                onProcessComplete()

                if (!isUserAuthedOnOnlyClientRef.current && !tempStorage.getItem(task.id)) {
                    const languageCode = currentLanguage.dg_code?.toLowerCase() ?? null
                    tempStorage.setItem(task.id, { video, languageCode })
                }
                setCurrentStage(EOnboardingStage.Configure)
            } catch (err) {
                const { message, response } = err
                const isSubscriptionError = [message, response?.data.message].some(
                    (field) =>
                        field.includes("exceeds the maximum limit") ||
                        field.includes("exceeds your allowance"),
                )

                if (isSubscriptionError) {
                    const errorMessage = response?.data.message || message
                    setError({
                        type: EErrorStackType.Subscription,
                        message: errorMessage,
                    })
                } else {
                    setError({
                        type: EErrorStackType.General,
                        message,
                    })
                }

                setCurrentStage(EOnboardingStage.Error)
                console.error(err)
            }
        } catch (err) {
            setError({
                type: EErrorStackType.General,
                message: err.message,
            })
            console.error(err)
        }
    }

    const initializeSnippetTask = async () => {
        if (!video) {
            return
        }
        const args: TPostSnippetInitialiseBody = {
            format: currentVideoFormat.value,
            title: video.title,
            sourceType: video.type,
        }
        if (video.type === EVideoTaskSource.Youtube) {
            args.youtubeUrl = video.src
        }
        if (video.type === EVideoTaskSource.Upload) {
            args.languageCode = currentLanguage.dg_code?.toLowerCase() ?? null
        }
        const { task } = await api().postInitializeSnippetTask(args)
        ongoingTaskId.current = task.id
        setCurrentStage(EOnboardingStage.Configure)

        // On initialize, add projectId to url query
        router.push(
            {
                pathname: router.pathname,
                query: {
                    ...router.query,
                    projectId: task.id,
                },
            },
            undefined,
            { shallow: true },
        )

        detectInitialisationError(video)
    }

    const detectInitialisationError = async (videoSource: TVideoSourceResult) => {
        // Track supported language errors
        if (!videoSource.requiresDetection) {
            const isLanguageSupported =
                !isYoutubeVideo || (isYoutubeVideo && videoSource.tracks.length > 0)
            if (!isLanguageSupported) {
                setError({
                    type: EErrorStackType.LanguageSupport,
                    message: "This video language is not yet supported.",
                })
                return
            }
        }

        if (videoSource.title && videoSource.duration) {
            const hasKeywords = containsSubstrings(
                videoSource.title.toLowerCase(),
                ["music", "video"],
                true,
            )
            const isShort = videoSource.duration < 60 * 7 // less than 7 minutes
            const isMusicVideo = hasKeywords && isShort
            if (isMusicVideo) {
                setError({
                    type: EErrorStackType.MusicVideo,
                    message:
                        "This looks like a music video. Our AI works better on longer videos without background music.",
                })
                return
            }
        }

        const scripts = detectUnsupportedScripts(videoSource.title)
        if (scripts.length > 0) {
            let hasError = true

            // Many English videos have a small amount of arabic text in the title, so we ignore those
            const arabicScript = scripts.find((script) => script.bcp47 === "ar")
            const hasMinimalArabic = arabicScript && arabicScript.proportion <= 0.15
            if (hasMinimalArabic) {
                hasError = false
            }
            if (hasError) {
                const [firstScript] = scripts
                setError({
                    type: EErrorStackType.General,
                    message: `${firstScript.language} is not yet a supported language. Please try another video.`,
                })
                return
            }
        }
    }

    useEffect(() => {
        if (!video || !initializeAfterMount) {
            return
        }
        isInitializedRef.current = true
        initializeSnippetTask()
    }, [video, initializeAfterMount])

    useEffect(() => {
        if (!isInitializedRef.current) {
            isInitializedRef.current = true

            if (!existingTaskId) {
                initializeSnippetTask()
            }
        }
    }, [])

    useEffect(() => {
        if (!firebaseUserId || !existingTaskId) {
            return
        }
        ongoingTaskId.current = existingTaskId
        processTask(existingTaskId)
        setFauid("")
    }, [firebaseUserId])

    const redirectUserToSignUp = (projectId: string) => {
        router.push(linkToSignup({ projectId }))
    }

    const processTask = async (taskId: string) => {
        if (!video) {
            return
        }
        try {
            const userFirebaseId = isUserAuthedOnOnlyClientRef.current ? firebaseUserId : undefined
            if (video.type === EVideoTaskSource.Youtube) {
                await api().postProcessYoutubeSnippetTask({
                    taskId,
                    languageCode: currentLanguage.dg_code,
                    thumbnailUrl: video.thumbnailUrl,
                    format: currentVideoFormat.value,
                    firebaseUserId: userFirebaseId,
                    email: userEmail,
                })
                onProcessComplete()
                return
            }

            await uploadAndProcessVideo()
        } catch (err) {
            const isSubscriptionError = err.message.includes("exceeds your allowance")
            if (isSubscriptionError) {
                setError({
                    type: EErrorStackType.Subscription,
                    message: err.message,
                })
            } else {
                setError({
                    type: EErrorStackType.General,
                    message: err.message,
                })
            }
            setCurrentStage(EOnboardingStage.Configure)
            console.error(err)
        }
    }

    const handleClickGetClips = async () => {
        if (!video || !ongoingTaskId.current || isUserAuthedOnOnlyClientRef.current) {
            return
        }

        if (isYoutubeVideo && requiresLanguageSelection) {
            AlertController.show({
                title: "Language selection required",
                message:
                    "Please select a language from the list of supported languages to proceed.",
                size: "md",
                primaryAction: {
                    label: "OK",
                },
                isDismissable: true,
            })
            return
        }

        setCurrentStage(EOnboardingStage.Processing)

        if (!isUserLoggedIn) {
            tempStorage.setItem(ongoingTaskId.current, {
                ...video,
                format: currentVideoFormat.value,
            })
            redirectUserToSignUp(ongoingTaskId.current)
            return
        }

        processTask(ongoingTaskId.current)
    }

    const handleClickGetFast = () => {
        if (!isUserLoggedIn) {
            platformSubscriptionModalRef?.current?.open()
            return
        }
        handleClickGetClips()
    }

    const handleClickFormat = (option: typeof defaultVideoFormat) => {
        onConfigurationChange({
            format: option.value,
        })

        Analytics.trackEvent(AE.AISnippet_ClickFormat, {
            format: option.value,
            isDummyMode,
        })
    }

    const handleChangeLanguage = (language: TLanguage) => () => {
        onConfigurationChange({ language })
    }

    const handleClickRetryVideo = () => {
        setCurrentStage(EOnboardingStage.Configure)
        ongoingTaskId.current = null
        setError(null)
        onRetry()
    }

    const renderThumbnailWithProgress = () => {
        return (
            <Box position="relative">
                {video && (
                    <Image
                        src={video.thumbnailUrl}
                        alt="Onboarding - thumbnail image"
                        width="100%"
                        maxHeight={200}
                        borderRadius="8px"
                        objectFit="cover"
                    />
                )}
                {requiresUpload && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        backgroundColor="rgba(255,255,255,0.5)"
                        borderRadius="8px"
                        display="flex"
                        alignItems="end"
                        padding="12px"
                    >
                        <ProgressLoadBar variant="project-options" percentageComplete={progress} />
                    </Box>
                )}
            </Box>
        )
    }

    const renderButtonRightIcon = () => {
        if (isLoading) {
            return <Spinner size="sm" color="white" />
        }
        return <MdArrowForward size={20} color="white" />
    }

    const renderFormatOption = (option: TVideoFormatOption) => (
        <Flex shrink={0} gap="8px" alignItems="center" cursor="pointer">
            <Image
                src={option.imageSrc}
                alt={option.label}
                width="40px"
                height="40px"
                borderRadius="8px"
            />
            <Text as="span" marginBottom={0} fontWeight={600}>
                {option.label}
            </Text>
        </Flex>
    )

    const renderErrorMessage = () => {
        if (!error) {
            return null
        }

        let onClickCta = null
        let ctaText = null
        let onClickSecondaryCta = null
        let secondaryctaText = null
        const errorText = error?.message || defaultErrorMessage

        const errorName = error!.type
        if (errorName === EErrorStackType.LanguageSupport) {
            onClickCta = null
        } else if (errorName === EErrorStackType.Subscription) {
            onClickCta = () => {
                setError(null)
                router.push("/clips/pricing")
            }
            ctaText = "Upgrade plan"
        } else if (errorName === EErrorStackType.MusicVideo) {
            onClickCta = handleClickRetryVideo
            ctaText = "Try another video"
            onClickSecondaryCta = () => {
                setError(null)
            }
            secondaryctaText = "Continue"
        } else {
            onClickCta = handleClickRetryVideo
            ctaText = "Try another video"
        }

        return (
            <Alert status="warning">
                <AlertIcon as="div" alignSelf="flex-start" marginTop="5px" marginRight="15px" />
                <VStack width="auto">
                    <Text as="span">{errorText}</Text>
                    {!!onClickCta && (
                        <HStack justifyContent="flex-end" width="full">
                            {!!onClickSecondaryCta && (
                                <Button
                                    width="auto"
                                    fontWeight={600}
                                    onClick={onClickSecondaryCta}
                                    color="rgba(0, 0, 0, 0.7)"
                                    backgroundColor="transparent"
                                    _hover={{ backgroundColor: "rgba(227, 170, 66, 0.1)" }}
                                >
                                    {secondaryctaText}
                                </Button>
                            )}
                            <Button
                                width="auto"
                                fontWeight={600}
                                onClick={onClickCta}
                                color="rgba(0, 0, 0, 0.7)"
                                backgroundColor="rgba(227, 170, 66, 0.6)"
                                _hover={{ backgroundColor: "rgba(227, 170, 66, 1)" }}
                            >
                                {ctaText}
                            </Button>
                        </HStack>
                    )}
                </VStack>
            </Alert>
        )
    }

    const renderCallToAction = () => {
        const loadingText = (() => {
            if (currentStage === EOnboardingStage.InitUploading) {
                return "Setting up"
            }
            if (currentStage === EOnboardingStage.Uploading) {
                return "Uploading"
            }
            return "Processing"
        })()
        let FastOption = null
        if (enableFastOption) {
            FastOption = (
                <Button
                    variant="primaryGreen"
                    size="lg"
                    margin="auto"
                    borderRadius="12px"
                    width="full"
                    onClick={handleClickGetFast}
                    isLoading={isLoading}
                    isDisabled={isFormDisabled}
                    loadingText={loadingText}
                >
                    <Text as="span" color="white" marginBottom="0">
                        Get Clips Fast
                        {!isLoading && (
                            <Box
                                display="inline-block"
                                padding="2px 4px 1px 4px"
                                marginLeft="10px"
                                borderRadius="4px"
                                backgroundColor="white"
                                fontSize="10px"
                                textTransform="uppercase"
                                fontWeight={600}
                                color="black"
                            >
                                PRO
                            </Box>
                        )}
                    </Text>
                </Button>
            )
        }
        return (
            <>
                <Button
                    variant="primary"
                    size="lg"
                    margin="auto"
                    borderRadius="12px"
                    width="full"
                    rightIcon={renderButtonRightIcon()}
                    onClick={handleClickGetClips}
                    isLoading={isLoading}
                    isDisabled={isFormDisabled}
                    loadingText={loadingText}
                >
                    <Text as="span" color="white">
                        Get Clips
                    </Text>
                </Button>
                {FastOption}
            </>
        )
    }

    const renderLanguageSelector = () => {
        const languageLabel = (() => {
            if (requiresLanguageSelection) {
                return "Language"
            }
            return isYoutubeVideo ? "Language:" : "Original Language:"
        })()
        const selectedLanguageLabel = (() => {
            if (requiresLanguageSelection) {
                if (error?.type === EErrorStackType.LanguageSupport) {
                    return "Not supported"
                }
                return "Select language"
            }
            return currentLanguage.name
        })()

        const isLanguageSupported = !isYoutubeVideo || (isYoutubeVideo && video.tracks.length > 0)

        // Filter out languages that are not supported by deepgram
        const languageOptionList = (() => {
            if (!isYoutubeVideo || !!video?.requiresDetection) {
                return [defaultVideoSourceLanguage, ...SupportedTranscriptionLanguages]
            }
            return SupportedTranscriptionLanguages.filter((lang) =>
                video.tracks.some((track) => track.deepgram === lang.dg_code),
            )
        })()
        return (
            <Flex alignItems="center">
                <Text as="span" marginBottom={0} color="rgba(0,0,0,0.5)" fontWeight={500}>
                    {languageLabel}
                </Text>
                <Menu gutter={0}>
                    <MenuButton
                        isDisabled={isFormDisabled}
                        as={Button}
                        variant="clear"
                        rightIcon={<ChevronDownIcon />}
                        width={["100%", null, "auto"]}
                        border={
                            requiresLanguageSelection && isLanguageSupported
                                ? "2px dashed rgba(0,0,0,0.1)"
                                : "2px dashed rgba(0,0,0,0)"
                        }
                        marginLeft={requiresLanguageSelection ? "5px" : 0}
                    >
                        {selectedLanguageLabel}
                    </MenuButton>
                    <MenuList
                        maxHeight={["10vh", null, "200px"]}
                        paddingY={0}
                        borderRadius="md"
                        overflow="auto"
                    >
                        {languageOptionList.map((lang) => (
                            <MenuItem
                                key={lang.dg_code}
                                onClick={handleChangeLanguage(lang)}
                                minH="40px"
                            >
                                {lang.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Flex>
        )
    }

    return (
        <Box margin="10% auto">
            <Flex flexDirection="column" shrink={0} gap="16px" width={370}>
                {renderLanguageSelector()}
                {renderThumbnailWithProgress()}
                <Text fontWeight={600} fontSize="16px" marginBottom={0}>
                    {video?.title}
                </Text>
                {!hasVideoError && (
                    <Menu>
                        <MenuButton
                            isDisabled={isFormDisabled}
                            variant="whiteWithLightOutline"
                            height="auto"
                            padding="16px 24px"
                            as={Button}
                            rightIcon={<ChevronDownIcon boxSize="25px" />}
                        >
                            {renderFormatOption(currentVideoFormat)}
                        </MenuButton>
                        <MenuList width={370} paddingY={0} borderRadius="md" overflow="hidden">
                            {VideoFormatMapping.map((option) => (
                                <MenuItem
                                    padding="16px 24px"
                                    key={option.value}
                                    onClick={() => {
                                        handleClickFormat(option)
                                    }}
                                    minH="40px"
                                >
                                    {renderFormatOption(option)}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                )}
                {renderErrorMessage()}
                {!hasVideoError && renderCallToAction()}
            </Flex>
        </Box>
    )
}

export default ProjectOptionsSelector
