import { Button, ButtonProps, Spinner, Text } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useContext, useMemo } from "react"
import { IoMdLock } from "react-icons/io"
import { MdAutoAwesome } from "react-icons/md"

import { AE, LinkedInEvents } from "../../@types/analytics"
import Analytics from "../../controllers/analytics_controller"
import { BaseUser } from "../../server/dao/user_dao"
import { PlatformSubscriptionModalContext } from "../../utils/context_util"
import { linkToCreateSnippets } from "../../utils/link_util"
import { canUserSubmitNewVideo } from "../../utils/user_util"

interface ICreateClipsButtonProps extends ButtonProps {
    currentUser: BaseUser
    location: "landing-page" | "projects" | "project-detail"
    label?: string
    isLoading?: boolean
    isDisabled?: boolean
    onPermittedClick?: () => void
}

const CreateClipsButton = ({
    currentUser,
    location,
    label,
    isLoading,
    isDisabled,
    onPermittedClick,
    ...rest
}: ICreateClipsButtonProps) => {
    const router = useRouter()
    const platformSubscriptionModalRef = useContext(PlatformSubscriptionModalContext)

    const canCreateSnippets = useMemo<boolean>(
        () => !currentUser || (currentUser && canUserSubmitNewVideo(currentUser)),
        [currentUser],
    )

    const handleCreateNewClips = () => {
        if (canCreateSnippets) {
            Analytics.trackEvent(AE.AISnippet_CreateClips, {
                location,
            })
            if (onPermittedClick) {
                onPermittedClick()
            } else {
                router.push(linkToCreateSnippets())
            }
        } else {
            Analytics.trackEvent(AE.AISnippet_CreateNewClipsPaywall, {
                location,
            })
            Analytics.trackLinkedInConversion(LinkedInEvents.LINKEDIN_AI_CREATE_CLIPS_PAYWALL)

            platformSubscriptionModalRef?.current?.open()
        }
    }

    const leftIcon = (() => {
        if (!canCreateSnippets) {
            return <IoMdLock size={20} color="white" />
        }
        if (isLoading) {
            return <Spinner size="sm" color="white" />
        }
        return <MdAutoAwesome size={20} color="white" />
    })()

    return (
        <Button
            variant="primary"
            size="lg"
            onClick={handleCreateNewClips}
            leftIcon={leftIcon}
            isLoading={isLoading}
            isDisabled={isDisabled}
            {...rest}
        >
            <Text as="span" color="white">
                {label ?? "Create new clips"}
            </Text>
        </Button>
    )
}

export default CreateClipsButton
