import { AE, TEventsWithEnhancedConversion } from "../@types/analytics"
import { ETrialType } from "../@types/subscription_types"
import { decodeString } from "./crypto_util"

// ADDING A NEW CAMPAIGN
// 1. Create a campaign key constant and add to `CAMPAIGN_LIST`
// 2. Use it to create entry in `TestCampaignMap`
// 3. Update `getCampaignProperties` to extract the value from the cookie
// 4. (optional) Store the value for each `User` at signup
// 5. Use the value in the code where needed

export enum EFaceDetectionSpeed {
    SlowChoice = "slow-choice",
    Slow = "slow",
    Fast = "fast",
}

export const CAMPAIGN_ENCODE_KEY = "dca043ad3b0b4c809a7bea1ff17298t7"

export const ALTERNATE_TRIAL_CAMPAIGN_KEY = "_lpc"
export const SLOW_FD_CAMPAIGN_KEY = "_slfd"

export const CAMPAIGN_LIST = [ALTERNATE_TRIAL_CAMPAIGN_KEY, SLOW_FD_CAMPAIGN_KEY] as const

// `defaultValue` is the value for users not in the campaign
export const TestCampaignMap = {
    "/p/07d828f3": {
        value: ETrialType.ThreeDays,
        cookieKey: ALTERNATE_TRIAL_CAMPAIGN_KEY,
        defaultValue: ETrialType.Regular,
    },
    "/p/0b3b1b3d": {
        value: ETrialType.NoTrial,
        cookieKey: ALTERNATE_TRIAL_CAMPAIGN_KEY,
        defaultValue: ETrialType.Regular,
    },
    "/p/6j5n6be4": {
        value: EFaceDetectionSpeed.Slow,
        cookieKey: SLOW_FD_CAMPAIGN_KEY,
        defaultValue: EFaceDetectionSpeed.Fast,
    },
    "/p/6n6v7i8f": {
        value: EFaceDetectionSpeed.SlowChoice,
        cookieKey: SLOW_FD_CAMPAIGN_KEY,
        defaultValue: EFaceDetectionSpeed.Fast,
    },
} as const

export const getCampaignProperties = (cookies: Partial<Record<string, string>>) => {
    const properties = {} as { trialType: ETrialType; slowFdTest: EFaceDetectionSpeed }

    if (ALTERNATE_TRIAL_CAMPAIGN_KEY in cookies) {
        const campaignValue = cookies[ALTERNATE_TRIAL_CAMPAIGN_KEY]
        if (!campaignValue) {
            properties.trialType = ETrialType.Regular
        } else {
            properties.trialType = decodeString(campaignValue, CAMPAIGN_ENCODE_KEY)
        }
    } else {
        properties.trialType = ETrialType.Regular
    }
    if (SLOW_FD_CAMPAIGN_KEY in cookies) {
        const campaignValue = cookies[SLOW_FD_CAMPAIGN_KEY]
        if (!campaignValue) {
            properties.slowFdTest = EFaceDetectionSpeed.Fast
        } else {
            properties.slowFdTest = decodeString(campaignValue, CAMPAIGN_ENCODE_KEY)
        }
    } else {
        properties.slowFdTest = EFaceDetectionSpeed.Fast
    }

    return properties
}
